import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { GlobalUrl } from 'src/app/global-url';
import { User } from '../entities/api/user';
import { Organisation } from '../entities/api/organisation';
import { GatewayMetaData } from '../entities/api/gateway';
import { OrganisationGatewayMetaData } from '../entities/api/organisationgatewaymetadata';
import { environment } from 'src/environments/environment';
import { FirmwareUpdateResponse } from '../entities/api/firmwareupdateresponse';
import { FirmwareRelease } from '../entities/api/firmwarerelease';
import { Gateway } from '../../data-explorer/entities/api/gateway';
import { DeviceControlResponse } from '../entities/api/devicecontrolresponse';
import { DeviceStatus } from '../entities/api/devicebuttonstatus';
import { ThingSetNodeValue } from '../entities/api/thingsetnodevalue';
import { InvokeFunctionRequest } from '../entities/api/invokefunctionrequest';
import { GetDevicesResponse } from '../entities/api/getdevicesresponse';
import { DevicesRequest } from '../entities/api/devicesrequest';
import { SetParametersRequest } from '../entities/api/setparametersrequest';
import { GatewayComponentUpdateResponse } from '../entities/api/gatewaycomponentupdateresponse';
import { ComponentVersion } from '../entities/api/componentversion';

@Injectable({
    providedIn: 'root'
})
export class AdminService {

    constructor(private http: HttpClient,
        private url: GlobalUrl) { }

    private get<T>(partialUrl: string): Promise<T> {
        return firstValueFrom(this.http.get<T>(this.url.badmintonUrl + partialUrl));
    }

    private post<T>(partialUrl: string, data: any): Promise<T> {
        return firstValueFrom(this.http.post<T>(this.url.badmintonUrl + partialUrl, data));
    }

    private put<T>(partialUrl: string, data: any): Promise<T> {
        return firstValueFrom(this.http.put<T>(this.url.badmintonUrl + partialUrl, data));
    }

    private delete<T>(partialUrl: string): Promise<T> {
        return firstValueFrom(this.http.delete<T>(this.url.badmintonUrl + partialUrl));
    }

    getAllOrganisations() {
        return this.get<Organisation[]>(`organisations`);
    }

    /**
      * Returns all groups for an organisation
    */
    getGatewayMetadata(organisation: Organisation) {
        return this.get<GatewayMetaData[]>(`organisations/${organisation.domain}/gatewaymetadata`);
    }
    /**
      * Returns all groups for an organisation
    */
    getAllGateways(organisation: Organisation) {
        return this.get<Gateway[]>(`organisations/${organisation.domain}/gateways`);
    }

    /**
      * Returns all users in an organisation
    */
    getAllUsers(organisation: Organisation) {
        return this.get<User[]>(`organisations/${organisation.domain}/users`);
    }

    /**
    * Creates user and returns the user name and id as part of the response
    */
    createUser(organisation: Organisation, user: User) {
        delete user.userId;
        return this.post<User>(`organisations/${organisation.domain}/users`, user);
    }

    /**
      * Delete user
    */

    deleteUser(organisation: Organisation, user: User) {
        return this.delete<User>(`organisations/${organisation.domain}/users/${user.email}`);
    }

    getBoards(organisation: Organisation) {
        return this.get<string[]>(`organisations/${organisation.domain}/brillos/update/boards`);
    }

    getReleasesForDevice(organisation: Organisation, deviceName: string, nodeIds: string[]) {
        return this.post<FirmwareRelease[]>(`organisations/${organisation.domain}/brillos/update/${deviceName}/releases`, nodeIds);
    }

    /**
    * Returns one organisation gateway meta data
    */
    getOrgGatewayMetaData(organisation: Organisation, gatewayId: number) {
        return this.get<OrganisationGatewayMetaData>(`organisations/${organisation.domain}/gatewaymetadata/${gatewayId}`);
    }

    /**
    * Edits OrgGatewayMetaData and returns the OrgGatewayMetaData object as a response
    */
    editOrgGatewayMetaData(organisation: Organisation, orgGatewayMetaData: OrganisationGatewayMetaData) {
        return this.put<OrganisationGatewayMetaData>(`organisations/${organisation.domain}/gatewaymetadata`, orgGatewayMetaData);
    }
    /**
    * Edits OrgGatewayMetaData and returns the OrgGatewayMetaData object as a response
    */
    addOrgGatewayMetaData(organisation: Organisation, orgGatewayMetaData: OrganisationGatewayMetaData) {
        return this.post<OrganisationGatewayMetaData>(`organisations/${organisation.domain}/gatewaymetadata`, orgGatewayMetaData);
    }

    addOrEditOrgGatewayMetaData(organisation: Organisation, orgGatewayMetaData: OrganisationGatewayMetaData, type: string) {
        if (type === 'Add') {
            return this.addOrgGatewayMetaData(organisation, orgGatewayMetaData);
        } else {
            return this.editOrgGatewayMetaData(organisation, orgGatewayMetaData);
        }
    }

    getAddressFromMapSearch(query: string) {
        return firstValueFrom(this.http.get<any>(`https://atlas.microsoft.com/search/address/json?&subscription-key=${environment.azureMapSubscriptionKey}&api-version=1.0&language=en-US&query=${query}`))
    }

    getAddressByLatAndLon(query) {
        return firstValueFrom(this.http.get<any>(`https://atlas.microsoft.com/search/address/reverse/json?api-version=1.0&subscription-key=${environment.azureMapSubscriptionKey}&language=en-US&query=${query}&number=1`))
    }

    private updateFirmware<T>(partialUrl: string, nodeIds: string[], hardwareVersion: string, commitHash: string, bootloader: boolean): Promise<T> {
        const params = new HttpParams()
            .set('hardwareVersion', hardwareVersion)
            .set('commitHash', commitHash)
            .set('bootloader', bootloader);
        return firstValueFrom(this.http.put<T>(this.url.badmintonUrl + partialUrl, nodeIds, { params }));
    }

    updateDeviceFirmware(organisation: Organisation, deviceName: string, nodeIds: string[], hardwareVersion: string, commitHash: string, bootloader: boolean) {
        return this.updateFirmware<FirmwareUpdateResponse>(`organisations/${organisation.domain}/brillos/update/${deviceName}/update`, nodeIds, hardwareVersion, commitHash, bootloader);
    }

    startDevice(organisation: Organisation, deviceName: string, nodeId: string) {
        return firstValueFrom(this.http.put<DeviceControlResponse>(this.url.badmintonUrl + `organisations/${organisation.domain}/brillos/control/${deviceName}/${nodeId}/start`, {}));
    }

    shutdownDevice(organisation: Organisation, deviceName: string, nodeId: string) {
        return firstValueFrom(this.http.put<DeviceControlResponse>(this.url.badmintonUrl + `organisations/${organisation.domain}/brillos/control/${deviceName}/${nodeId}/shutdown`, {}));
    }

    private async getStatus(statusPromise: Promise<DeviceControlResponse>): Promise<DeviceStatus> {
        try {
            const data = await statusPromise;
            return {
                isOnline: data.isOnline,
            };
        } catch (error) {
            throw error;
        }
    }

    getDevices(organisation: Organisation): Promise<GetDevicesResponse[]> {
        return firstValueFrom(this.http.get<GetDevicesResponse[]>(this.url.badmintonUrl + `organisations/${organisation.domain}/brillos/devices`, {}));
    }

    getParameters(organisation: Organisation, deviceName: string, request: DevicesRequest): Promise<ThingSetNodeValue[]> {
        return firstValueFrom(this.http.post<ThingSetNodeValue[]>(this.url.badmintonUrl + `organisations/${organisation.domain}/brillos/parameters/${deviceName}`, request));
    }

    getFunctions(organisation: Organisation, deviceName: string, request: DevicesRequest): Promise<ThingSetNodeValue[]> {
        return firstValueFrom(this.http.post<ThingSetNodeValue[]>(this.url.badmintonUrl + `organisations/${organisation.domain}/brillos/parameters/${deviceName}/functions`, request));
    }

    invokeFunction(organisation: Organisation, deviceName: string, request: InvokeFunctionRequest) {
        return firstValueFrom(this.http.put<any>(this.url.badmintonUrl + `organisations/${organisation.domain}/brillos/parameters/${deviceName}/functions`, request));
    }

    restartGateway(organisation: Organisation, deviceName: string) {
        return firstValueFrom(this.http.put<any>(this.url.badmintonUrl + `organisations/${organisation.domain}/brillos/control/${deviceName}/restart`, {}));
    }

    setParameters(organisation: Organisation, deviceName: string, nodeIds: string[], thingSetNodeValues: ThingSetNodeValue[]) {
        return firstValueFrom(this.http.put<any>(this.url.badmintonUrl + `organisations/${organisation.domain}/brillos/parameters/${deviceName}/`, <SetParametersRequest>{ nodeIds: nodeIds, parameters: thingSetNodeValues }));
    }

    getDeviceConfiguration(organisation: Organisation, deviceName: string): Promise<any> {
        return firstValueFrom(this.http.get<any>(this.url.badmintonUrl + `organisations/${organisation.domain}/brillos/config/${deviceName}`, {}));
    }

    updateDeviceConfiguration(organisation: Organisation, deviceName: string, updatedTwin: string): Promise<any> {
        return firstValueFrom(this.http.put<any>(this.url.badmintonUrl + `organisations/${organisation.domain}/brillos/config/${deviceName}`, updatedTwin));
    }

    getDeviceLogs(organisation: Organisation, deviceName: string, since: string): Promise<any> {
        return firstValueFrom(this.http.get<any>(this.url.badmintonUrl + `organisations/${organisation.domain}/brillos/logs/${deviceName}/${since}`, { responseType: 'text' as 'json' }));
    }

    getGatewayComponentVersion(organisation: Organisation, componentName: string): Promise<string[]> {
        return firstValueFrom(this.http.get<string[]>(this.url.badmintonUrl + `organisations/${organisation.domain}/brillos/gateway/component/version/${componentName}`));
    }

    getGatewayCurrentVersions(organisation: Organisation, gatewayName: string): Promise<ComponentVersion[]> {
        return firstValueFrom(this.http.get<ComponentVersion[]>(this.url.badmintonUrl + `organisations/${organisation.domain}/brillos/gateway/version/${gatewayName}`));
    }

    updateComponentWithNewVersion(organisation: Organisation, gatewayName: string, componentName: string, imagePath: string): Promise<GatewayComponentUpdateResponse> {
        return firstValueFrom(this.http.put<GatewayComponentUpdateResponse>(this.url.badmintonUrl + `organisations/${organisation.domain}/brillos/update/gateway/component/${gatewayName}/${componentName}`, { imagePath }));
    }
}
